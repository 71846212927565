import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ETAtime, getlocationlistmapId, getmapDetialmapId, getproductlist, getstorelist } from '../API'
import { SliderData } from '../Data/Storelist'
import Footer from './Footer'
import Header from './Header'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useDispatch } from 'react-redux'
import { storecurrency } from '../redux/Actions'
function StoreList() {
  const navigate =useNavigate()
   const dispatch =useDispatch()
  const handlestoreclick=(item)=>{
      let storeId = item.storeId
        if(item.type==='hms'){
          dispatch(storecurrency(item.currency))
          navigate('/selectDetails' , {state:{storeId , item}})
          localStorage.setItem('store_type', "hms")
        }
        else{
          dispatch(storecurrency(item.currency))
          navigate(`/storelist/${item.storeId}`,{state:{item:item}})
          localStorage.setItem('store_type', "self")
        }
        
  }
  const [storelist, setstorelist] =useState()
  const [mapName, setmapName] =useState()
  useEffect(()=>{
    getstorelist().then((res)=>{
        // console.log('Storelist response',res)
        setstorelist(res.data.message?.sort((a, b) => {
          const nameA = a.storeId.toLowerCase();
          const nameB = b.storeId.toLowerCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        }))
       
    }).catch((err)=>{
          //  console.log(err)
    })
    getlocationlistmapId().then((res)=>{
        //  console.log('getlocationlistmapId response', res)
         setmapName(res.data.data.mapName)
    })
    .catch((err)=>{

    })
  
  },[])
  const [ETAarray, setETAarray] = useState([]); // Initialize ETAarray with an empty array
  useEffect(() => {
    const promises = storelist?.map((item) => {
      return ETAtime(item.storeId)
        .then((res) => {
          return [res.data.message,res.data.service];
        })
        .catch((err) => {
          // console.error(err);
          return null;
        });
    });
    Promise.all(promises)
      .then((results) => {
        // Filter out null values (failed promises) from the results
        const filteredResults = results.filter((message) => message !== null);
        setETAarray(filteredResults); // Update the state with the resolved messages
      })
      .catch((err) => {
        // console.error(err);
      });
  }, [storelist]); 

    return (
    <div className='MainWhole-wrapper-container'>  
      <Header/>
      <div className='ItemListMain-Wrapper'>
        <div className="ItemList_page_Content_wrapper"> 
           <h1> Hello @{mapName}</h1>
            <p> We’ve got great options if you’ve got time.</p>
        </div>
        <div className='Storelist-container-wrapper'>
          {/* {   console.log('storelist',storelist)} */}
            <h3> {storelist?.length} stores near you </h3>
         {storelist?.map((item, index)=>{
          
            if(ETAarray[index]!==undefined){
              return(
                <div key={index} className='Storelist_container_inner_item_wrapper' onClick={ ()=> {(item.status === 0 || item.capacity || !ETAarray[index][1]) || handlestoreclick(item)}}> 
                   <img src={item.image} className={item.status === 0 || item.capacity || !ETAarray[index][1] ? "inactive_store":''} alt=""/>
                   <div>  
                   {item.status===0 || item.capacity || !ETAarray[index][1] ? 
                    <span className="Storelist_page_comingsoon_img">
                     {/* <img src="/assets/images/coming-soon.svg"/> */}
                    </span>:''} 
                       <div className='Storelist_container_inner_item_wrapper_content_div'> 
                           <p className={item.status===0 || item.capacity || !ETAarray[index][1] ? 'inactive_store':"d_flex_start Storelist_container_inner_item_wrapper_content_p"}><AccessTimeOutlinedIcon/> {ETAarray[index]}</p>
                           {/* {console.log("ETAarray:", ETAarray)} */}
                           <h4>{item.en.name}</h4>
                           {/* <h6>{item.distance}</h6> */}
                           {/* {console.log('item',item)} */}
                           <p> {item.en.description}</p>   
                           <h6 className={item.status===0 || item.capacity ||!ETAarray[index][1] ? "inactive_store":''}>{item.location}</h6>  
                           {item.status === 0|| item.capacity || !ETAarray[index][1] ? <p className='p_error'> Currently Unavailable</p> :null}
                       </div> 
                       </div>
                </div>
               )
            }
        
         })}   
          
       </div> 
      </div>
      <Footer/>
    </div>
  )
}

export default StoreList
