import React, { useEffect, useState } from 'react'
import Header from './Header'
import $ from 'jquery'
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from 'react-router-dom';
import Footer from './Footer';
import { emphasize } from '@mui/material';
import { ETAtime, ETAtimetwo, getdeliverytime, OrderQrcode, sendmsglocation } from '../API';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OTPInput, { ResendOTP } from "otp-input-react";
import { loadStripe } from '@stripe/stripe-js';
import HeaderCongratulations from './HeaderCongratulations';
import { useSelector } from 'react-redux';
function Congratulations() {


  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const { storeCurrency } =useSelector(state=>state.storeCurrencyReducer)

  const selectedDelivery=sessionStorage.getItem('deliveryLocation')
  const [remaintime, setremaintime]=useState()
  const [open, setOpen] = React.useState(false);
  const [opentwo, setOpentwo] = React.useState(false);
  const [otp, setOtp] = useState('1234'); 
  const [etaTime, setEtaTime] =useState()
  var rString=''
  var finalprice=0
  useEffect(()=>{
    // console.log('orderData',orderData)
    ETAtimetwo(orderData?.state?.state?.statetwo?.storeId ,orderData?.state?.state?.orderId).then((res)=>{
      setEtaTime(res.data.message)
    }).catch((err)=>{

    })
  },[])
  const orderData =useLocation()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor:'#ffffff',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px',
    paddingTop: '11px',
    paddingBottom:'0px',
    border:'none',
    paddingRight:'0px',
    paddingLeft:'0px',
    zIndex:'999999999999999999999999'
  };
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  }
  const handleClosetwo = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpentwo(false);
  }
// console.log('paymentIntentId',paymentIntentId)

const handleOpen = () => setOpen(true);
const handleopentwo = () => setOpentwo(true);
  const [newstring, setnewstring] =useState()
   useEffect(()=>{

    // generateBarCode(orderData.state.newstring)
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hrs= today.getHours(); // => 9
    let mins=today.getMinutes(); // =>  30
    var ampm = hrs >= 12 ? 'pm' : 'am';
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12
    if (dd < 10){
      dd = '0' + dd;
    } 
    if (mm < 10){
      mm = '0' + mm;
    } 
    if(hrs < 10){
      hrs ='0'+hrs
    }
    if(mins < 10){
      mins ='0'+mins
    }
    const formattedToday = mm + '-' + dd + '-' + yyyy + '\xa0\xa0\xa0\xa0\xa0'+hrs +":" + mins +'\xa0\xa0'+ ampm;
    document.getElementById('DATE').innerHTML = formattedToday;
  //  setInterval(()=>{
  //   getdeliverytime(orderData?.state?.newstring).then((res)=>{
  //     // console.log('Response timer',res)
  //     setremaintime(res.data?.data[0]?.deliveryTime)
  //    }).catch((err)=>{
  //        console.log(err)
  //    })
  //  }, 4000)  
  setOtp(orderData?.state?.state?.pin)
   },[])

    function generateBarCode(orderId)
    {
        var url = 'https://api.qrserver.com/v1/create-qr-code/?data=' + orderId + '&amp;size=50x50';
        $('#barcode').attr('src', url);
        OrderQrcode(orderId,url).then((res)=>{
            //  console.log('QRlInk reposne',res)
        }).catch((err)=>{
          console.log(err)
        })
        return rString
    }
 
    const { t } = useTranslation() 
  //   function startTimer(duration, display) {
  //     var timer = duration, minutes, seconds;
  //     setInterval(function () {
  //         minutes = parseInt(timer / 60, 10)
  //         seconds = parseInt(timer % 60, 10);
  
  //         minutes = minutes < 10 ? "0" + minutes : minutes;
  //         seconds = seconds < 10 ? "0" + seconds : seconds;
  
  //         display.textContent = minutes + ":" + seconds;
  
  //         if (--timer < 0) {
  //             timer = duration;
  //         }
  //     }, 1000);
  // }
  
  // window.onload = function () {
  //     var fiveMinutes = 60 * 20,
  //         display = document.querySelector('#time');
  //     startTimer(fiveMinutes, display);
  // };
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  finalprice=parseFloat(orderData?.state?.state?.totalprice) + parseFloat(orderData?.state?.state?.totaltax) +parseFloat(orderData?.state?.state?.deliveryCharge) 
    return (
    
     <>
      <div className='MainWhole-wrapper-container'>  
      {/* {console.log('orderDataorderData',orderData)} */}
       {/* <Header open={open} opentwo={opentwo}/> */}
     <HeaderCongratulations orderData={orderData}/>
    <div className='Congratulation-Main-wrapper'>
       <div className='Congratulation-content-div-one-wrapper'>
             <div className='Congratulation-content-div-one-wrapper-inner-one'> 
               <img src="/assets/images/noun-check-mark-747257.png"/>    
            </div>
           <div className='Congratulation-content-div-one-wrapper-inner-two'>
                <h1> 
                  {/* {t('congratulations_page_heading')} */}
                    Congratulations
                  </h1>
                <p> Your Order has been placed </p> 
                
           </div>
       </div>
       <div className='Congratulation-page-order-wrapper-below-QR'> 
        <div> 
            <h5 > Order ID:</h5>
             {orderData?.state?.state?.orderId}
        </div>    
        <div> 
            <h5 >Order date:</h5>
            <p id="DATE">  </p>
        </div>
        <div> 
          <h5> Payment </h5>
          <p> Stripe</p>
        </div>
     </div> 
     <div className="Congratulations_page_QRcode_pin_wrapper">
         <h3> PIN to access your package</h3>  
         <div className='QR-Code-main-inner'> 
               <OTPInput inputClassName="readonly-input" 
                          onKeyDown={handleKeyDown}  value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false}  className="Congratulation_page_otp_input" />
        </div>
    </div> 
     <div className="Congratulations_page_QRcode_pin_wrapper"> 
      <h4> When restaurant accept your order, you will be receiving the Tracking URL on SMS</h4>
     </div>
     <Modal     open={open}
                // BackdropProps={false}
                onHide={handleClose}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">  
      <Box sx={style}>
          <div className='QR-Code-wrapper-whole'> 
            <div className='QR-Code-wrapper-whole-inner-one'>
              <h3> Scan QR Code</h3>
              <div className='Sorry_div_wrapper_inner_div' style={{paddingBottom:'0px'}} onClick={handleClose}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            </div>       
            <p style={{marginBottom:'12px'}}>  Show the QR code to the camera</p>
            <div className='QR-Code-main-inner'> 
          <img id='barcode' 
                src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100" 
                alt="" 
                title="Congratualtions" 
                width="500" 
                height="500"
                style={{objectFit:'cover'}} />
            </div>      
        </div>
        </Box>
        </Modal> 
        <Modal     open={opentwo}
                // BackdropProps={false}
                onHide={handleClosetwo}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">  
      <Box sx={style}>
          <div className='QR-Code-wrapper-whole'> 
            <div className='QR-Code-wrapper-whole-inner-one'>
              <h3> Your PIN</h3>
              <div className='Sorry_div_wrapper_inner_div' style={{paddingBottom:'0px'}} onClick={handleClosetwo}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            </div>       
            <p style={{marginBottom:'12px'}}>  Enter the PIN on the Top Screen</p>
            <div className='QR-Code-main-inner'> 
               <OTPInput  value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={true}  className="Cart_page_otp_input" />
            </div>      
        </div>
        </Box>



        </Modal>
     {/* <div className='QR-Code-wrapper-whole-inner-one'>
           <h4>Estimated time for delivery<br />is XX minutes.</h4>
          </div> */}
             <div className="BillDetails_page_BillTotal_wrapper" style={{margin:'20px'}}> 
              <h3> Order Details</h3>
              <div className="DetailsPage-content-wrapper" style={{paddingTop: "10px"}}>
                   {orderData?.state?.state?.checkoutData?.map((item ,index)=>{
                   return (
                    <div key={index} className='d_grid_column BillDetails_item_total_wrapper'> 
                      <div className="text_align_start"> <h4> {item.en.name} x{item.count} </h4></div>
                      <div className="text_align_end"> <p>{storeCurrency}{item.price.toFixed(2)}</p></div> 
                   </div>
                   )
                   })}
                   <div className='BillDetails_item_totalwhole_wrapper'>
                      <div className='d_grid_column BillDetails_item_total_wrapper'> 
                        <div className="text_align_start"> <h4> Delivery Fee</h4></div>
                        <div className="text_align_end"> <p> {storeCurrency}{orderData?.state?.state?.deliveryCharge.toFixed(2)}</p></div> 
                       </div> 
                       <div className='d_grid_column BillDetails_item_total_wrapper'> 
                        <div className="text_align_start"> <h4> Sales Tax(6%) </h4></div>
                        <div className="text_align_end"> <p>{storeCurrency}{orderData?.state?.state?.totaltax}</p></div> 
                       </div>  
                       <div className='d_grid_column BillDetails_item_totalprice_wrapper'> 
                        <div className="text_align_start"> <p> Total</p></div>
                        <div className="text_align_end"> <p>{storeCurrency}{finalprice.toFixed(2)}</p></div> 
                       
                     </div> 
                  </div>   
                    <div className='d_grid_column BillDetails_item_topay_wrapper'> 
                     <div className="text_align_start"> <p> Selected Gate</p></div>
                     <div className="text_align_end"> <p> {orderData?.state?.state?.deliveryGate}</p></div> 
                    </div>  
              </div>
           </div>
           {/* <div className="DetailsPage-content-wrapper" style={{margin: "20px"}}>
           <div className='d_grid_column Congratulations_item_total_wrapper'> 
                     <div className="text_align_start"> <h4>Selected Gate</h4></div>
                     <div className="text_align_end"> <p className="selectdelivery_gate_p"> {selectedDelivery}</p></div> 
                    </div> 
           </div> */}
       <div className='Congratulations_page_estimated_time_div' id="Estimated_time"> 
          <p>  Estimated Delivery Time   </p> 
          <h3> 
             {/* {((orderDetails?.deliveryTime)/60).toFixed(0)} */}
            {etaTime}
             </h3>
       </div>
    </div>
   </div> 
  
   <Footer open={open} opentwo={opentwo}/>
    </>
  )
}

export default Congratulations